
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import '~vuetify/src/styles/settings/_variables';

.clientcabin-proposal::v-deep {
    background-color: $white;
    color: $black;

    .inverted-icons {
        .v-image__image {
            filter: invert(1);
        }
    }

    .banner-traffic {
        border: 1px solid #e7e7e7;
        border-radius: 9px;
        background-color: $white;
    }

    .feature-list .feature {
        color: $black;
        border: 1px solid #e7e7e7;
        border-radius: 9px;
        height: 100%;
        margin: 0 35px;

        &:not(.last):before {
            content: '';
            width: 50px;
            height: 16px;
            float: left;
            background: url('@/assets/img/clientcabin/proposal/arrow-right.png')
                no-repeat center center;
            background-size: auto 16px;
            position: absolute;
            top: 50%;
            right: -60px;
            transform: translate(0, -50%);
        }
    }

    .partners-logo {
        overflow: hidden;
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0;
            margin: 0 -36px -30px -36px;
            list-style: none;

            li {
                padding: 0 36px 30px 36px;
                margin: 0;
            }
        }
    }

    .repurposes-tabs {
        .v-tabs-bar,
        .v-tabs-items {
            background-color: transparent;
        }

        .v-tab {
            justify-content: left;
            text-transform: none;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0;
            margin: 0 -36px -30px -36px;
            list-style: none;

            li {
                padding: 0 36px 30px 36px;
                margin: 0;
            }
        }
    }

    .proposals {
        max-width: 1000px;
    }

    .proposal-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .v-image {
            line-height: 128px;
            max-height: 128px;
            margin-bottom: 40px;
        }
    }

    .typeform {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .faq {
        .v-expansion-panels {
            border: 1px solid $tertiary-color;
            border-radius: 9px;

            .v-expansion-panel {
                background-color: transparent;
            }
        }

        .v-expansion-panel-header {
            color: $black !important;

            .v-expansion-panel-header__icon svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    .with-shadow {
        box-shadow: 0 0 25px hsla(0, 0%, 0%, 0.3);
    }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .clientcabin-proposal::v-deep {
        .feature-list .feature {
            margin: 30px 0;
            height: auto;

            &:not(.last):before {
                content: '';
                width: 16px;
                height: 28px;
                background: url('@/assets/img/clientcabin/proposal/arrow-down.png')
                    no-repeat center center;
                background-size: auto 28px;
                top: auto;
                bottom: -42px;
                right: auto;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }
}
