
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import '~vuetify/src/styles/settings/_variables';

.clientcabin-pricing::v-deep {
    .pricing-table {
        border: 1px solid #e3ecf0;
        border-spacing: 0;
        table-layout: auto;
        width: 100%;

        tr:first-child td:first-child {
            border-top-left-radius: 8px;
        }

        tr:first-child td:last-child {
            border-top-right-radius: 8px;
        }

        tr:last-child td:first-child {
            border-bottom-left-radius: 8px;
        }

        tr:last-child td:last-child {
            border-bottom-right-radius: 8px;
        }

        .sticky {
            position: sticky;
            top: 64px;
            background-color: white;

            &.second-row {
                top: 132px;
            }

            &.buttons-row {
                top: 248px;
            }
        }

        tr {
            td {
                text-align: center;

                &:not(.align-top) {
                    vertical-align: middle;
                }
            }

            td:first-of-type {
                text-align: left;
            }
        }

        tr:nth-child(even) {
            background-color: #fbfbfb;
        }

        tr.header {
            background-color: $clientcabin-darkblue;
            color: white;

            td {
                border-color: $clientcabin-darkblue;
            }
        }

        td {
            padding: 10px 15px;
            vertical-align: top;
            border-right: 1px solid #e3ecf0;
            border-bottom: 1px solid #e3ecf0;

            img:first-of-type {
                margin-left: 10px;
            }

            img + img {
                margin-left: 10px;
            }

            .d-flex {
                flex-wrap: wrap;
            }
        }
    }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .clientcabin-pricing::v-deep {
        .pricing-table {
            font-size: 80%;

            td {
                padding: 2px 3px;
            }

            .sticky {
                top: 64px;

                &.second-row {
                    top: 105px;
                }

                &.buttons-row {
                    top: 200px;
                }
            }
        }
    }
}
